import React, { useState, useRef } from 'react';
import { makeStyles, Grid, Box, Typography, TextField, Dialog } from '@material-ui/core';
import { Form, Formik } from 'formik';
import ButtonCircularProgress from 'src/component/ButtonCircularProgress';
import axios from 'axios';
import * as yup from 'yup';
import ApiConfig from 'src/config/APICongig';
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
    main: {
        padding: "30px 20px 60px 20px"
    },
    mainContainer: {
        padding: "20px",
        borderRadius: "50px",
        background: "url('./images/Section 5.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: "#FFF"
    },
    img: {
        maxWidth: "550px",
        width: "100%",
        borderRadius: "50px",
        height: "436px",
        "@media(max-width:600px)": {
            borderRadius: "25px",
        },
        "@media(max-width:959px)": {
            height: "auto",
        }
    },
    inputBox: {
        padding: "7px 20px",
        borderRadius: "50px",
        background: "rgba(255, 255, 255, 0.20)",
        maxWidth: "450px",
    },
    input: {
        color: " #FFF ! important",
        fontFamily: "Inter",
        fontWeight: " 400",
        fontSize: "14px",
        lineHeight: "130%",
        background: "transparent",
        border: "none",
        width: "90%",
        '& input::placeholder': {
            color: '#c7c5c5 !important', // Change this to the desired placeholder color
        },
        "& .MuiInputBase-root": {
            color: "#fff",
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none"
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none"
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none"
        }
    },
    some: {
        display: "flex",
        gap: "20px",
        "@media(max-width:600px)": {
            flexDirection: "column",
            gap: "0px",
        }
    },
    btn: {
        padding: '11px',
        borderRadius: "50px",
        background: "var(--brand, linear-gradient(136deg, #0C44AC 0%, #8338EC 100%))",
        color: "#fff",
        maxWidth: "125px",
        width: "100%",
        border: "none",
        fontSize: "14px",
       display:'flex',
       justifyContent:"center",
       alignItems:'center',
        height: "43px",
        fontFamily: "Inter",
        fontWeight: " 500",
        lineHeight: "130%",
        letterSpacing: "0.5px"
    },
    heading: {
        wordBreak: "keep-all",
        color: " #FFF",
        fontFamily: "Inter",
        fontWeight: " 600",
        fontSize: "60px",
        fontStyle: "normal",
        lineHeight: "130%",
        "@media(max-width:600px)": {
            fontSize: "37px",
        }
    },
    headBox: {
        padding: "10% 20px 0px 10%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        "@media(max-width:959px)": {
            padding: "50px 50px",
        },
        "@media(max-width:450px)": {
            padding: "25px 0px",
        }
    },
    dialogContent: {
        wordBreak: "keep-all",
        textAlign: "left",
        color: "#FFF",
        fontFamily: "Inter",
        fontWeight: " 500",
        fontSize: "17px",

        lineHeight: "22px",
        letterSpacing: "-0.408px",
    },
    dialogBox: {
        display: "flex",
        gap: "20px",
        padding: "20px"
    }
}));
const Experience = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const formRef = useRef(null);
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const defaultValues = {
        email: '',
    };

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .required("Please enter your email")
            .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please enter a valid email")
            .max(256, 'Email cannot more then 256 characters'),
    });

    const joinWaitList = async (values) => {
     
        try {
            setLoading(true)
            const res = await axios({
                method: "POST",
                url: ApiConfig.joinWaitlist,
                params: {
                    email: values.email.toString(),
                }
            });

            if (res.data.responseCode === 200) {
                setLoading(false)
                setDialogOpen(true)
            } 
            
        } 
        catch (error) {
            console.log (error.response.data.responseMessage)
              setLoading(false);
              if(error.response.data.responseMessage == "Join Waiting User already exists."){
                toast.error("Already subscribed")
              }else{
              toast.error(error.response.data.responseMessage)}
            
        }
    }
    return (
        <div className={classes.main}>
            <Box className={classes.mainContainer}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box className={classes.headBox}>
                            <Typography className={classes.heading}> Experience <br /> seamless <br /> travel with us</Typography>
                            <Formik initialValues={defaultValues} validationSchema={validationSchema} onSubmit={(values,{resetForm})=>{joinWaitList(values);resetForm();}}  >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    touched,
                                    values,
                                }) => (
                                    <Form action="" style={{ width: "100%" }} ref={formRef}>
                                        <Box className={classes.some}>
                                            <Box style={{ display: "flex", flexDirection: "column", maxWidth: "450px", width: "100%" }}>
                                                <Box className={classes.inputBox}>
                                                    <TextField placeholder="Enter your mail here.." className={classes.input}
                                                        type="email"
                                                        name="email"
                                                        value={values.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.email && errors.email)}
                                                    />
                                                </Box>
                                                <p style={{ color: 'red', padding: "0px 20px" }}>{touched.email && errors.email}</p>
                                            </Box>
                                            <button className={classes.btn} type="submit">{loading ? <ButtonCircularProgress /> : "Join Waitlist"}</button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box style={{ textAlign: "center" }}>
                            <img src="./images/map.png" alt="" className={classes.img} />
                        </Box>
                    </Grid>
                </Grid>
                <Dialog
                    open={dialogOpen}
                    PaperProps={{
                        style: {
                            maxWidth: "484px",
                            width: "100%",
                            maxHeight: "100%",
                            borderRadius: "12px",
                            textAlign: "center",
                            background: "#242C32",
                            backgroundImage: "url('./images/Ellipse 48.png')",
                            backgroundRepeat: "no-repeat",
                            backgroundPositionY: "-50px",
                            backgroundPositionX: "-50px",
                            padding: " 12px 16px",
                        },
                    }}>
                    <Box style={{ display: 'flex', justifyContent: "end" }}>
                        <img src="./images/cross.svg" alt="" onClick={handleDialogClose} />
                    </Box>
                    <Box className={classes.dialogBox}>

                        <Box style={{ display: 'flex', alignItems: "center" }}>
                            <img src="./images/check.svg" alt="" style={{ maxWidth: "20px" }} />
                        </Box>
                        <Typography className={classes.dialogContent}>We’ve added your email address to our waitlist. We’ll let you know when vadait is ready.</Typography>
                    </Box>
                </Dialog>
            </Box>
        </div >
    );
}

export default Experience;
