const url = "https://nodepune-webapp.mobiloitte.io/api/v1";
// const url = "http://172.16.6.52:3040/api/v1";
// const url = "http://172.16.6.52:3038/api/v1";

const user = `${url}/user`;
const admin = `${url}/admin`;


const ApiConfig = {
  joinWaitlist:`${user}/joinWaitingList`

  // userLogin: `${admin}/login`,
  // forgotPassword: `${admin}/forgotPasswordSendOTP`,
  // verifyOTP: `${admin}/verifyForgotPasswordOTP`,
  // resetPassword: `${admin}/resetPassword`,
  // changepassword: `${admin}/changePassword`,
  // loginOTPVerify: `${admin}/loginOTPVerify`,
  // changePasswordSendOTP: `${admin}/changePasswordSendOTP`,
  // changePasswordOTPVerify: `${admin}/changePasswordOTPVerify`,
  // listUser: `${admin}/listUser`,
  // userDetails: `${admin}/viewUserProfile`,
  // blockUnblock: `${admin}/blockUnblockUser`,
  // deleteUser: `${admin}/deleteUser`,
  // allBanner: `${url}/banner/get-all-banners`,
  // addBanner: `${url}/banner/addBanner`,
  // editBanner: `${url}/banner/editBanner`,
  // deleteBanner: `${url}/banner/deleteBanner`,
  // allBannerWeb: `${url}/banner/get-all-banners-of-web`,
  // addBannerWeb: `${url}/banner/addbannerOfWeb`,
  // editBannerWeb: `${url}/banner/editBannerofWeb`,
  // deleteBannerWeb: `${url}/banner/deleteBannerofWeb`,
  // editDiscount: `${admin}/updatePlanData`,
  // announcementContentList: `${url}/announcement/announcementContentList`,
  // viewannouncementContent: `${url}/announcement/viewannouncementContent`,
  // editannouncementContent: `${url}/announcement/editannouncementContent`,
  // deleteannouncement: `${url}/announcement/deleteannouncement`,
  // addannouncementContent: `${url}/announcement/addannouncementContent`,
  // discountManagementData: `${url}/buy/getTradingBotDetails`,
  // dashboardCount: `${url}/admin/adminDashbordcount`,
  // assetsData: `${admin}/assetsTransactionList`,
  // referralData: `${admin}/referralList`,
  // assetsTransactionList: `${url}/admin/assetsTransactionList`,
  // approveWithdrawReq: `${url}/admin/approveWithdrawReq`,
  // viewUserSubscriptionDetails: `${url}/admin/viewUserSubscriptionDetails`,
  // viewUserLoginActivity:`${url}/admin/viewUserLoginActivity`,
  // viewUserWalletTransactions:`${url}/admin/viewUserWalletTransactions`,
  // viewUserConnectedExchanges:`${url}/admin/viewUserConnectedExchanges`,
  // getListStatic:`${url}/static-content/staticContentList`,
  // getSingleStaticContent:`${url}/static-content/getSingleStaticContent`,
  // editStaticContent:`${url}/static-content/editStaticContent`,
  // contactUsList:`${url}/admin/contact-us-list`,
  // viewContactUs:`${url}/admin/viewContactUs`,
  // newsLettersubscriberList:`${url}/admin/newsLettersubscriberList`,
  // sendNotificationToAllUser:`${url}/admin/sendNotificationToAllUser`,
  // adminDashbordcount:`${url}/admin/adminDashbordcount`,
  // viewUserReferralDetails:`${url}/admin/viewUserReferralDetails`,
};

export default ApiConfig;

