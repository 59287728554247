import React, { useState } from 'react'
import { makeStyles, Grid, Box, Typography, TextField, Dialog } from "@material-ui/core"
import { Form, Formik } from 'formik';
import Axios from 'axios';
import * as yup from 'yup';
import ApiConfig from 'src/config/APICongig';
import ButtonCircularProgress from 'src/component/ButtonCircularProgress';
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
    main: {
        padding: "30px 20px",
    },
    box: {
        backgroundRepeat:"no-repeat",
        backgroundSize:"cover",
        background:"url('./images/homeback.png')",
        borderRadius: "50px",
    },
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "100px",
        padding: "35px 0px 20px 44px",
        "@media(max-width:959px)": {
            padding: "35px 35px 20px 35px",
       
        },
        "@media(max-width:500px)": {
            padding: "20px 20px 60px 20px",
            gap: "50px",
        }
    },
    text: {
        color: "#FFF",
        fontFamily: "Playfair Display",
        fontSize: "29.948px",
        fontStyle: "normal",
        fontWeight: " 400",
        lineHeight: " 11.773px",
    },
    logo: {
        display: "flex",
        alignItems: "center",
        gap: "5px"
    },
    text2: {
        wordBreak: "keep-all",
        color: " #FFF",
        fontFamily:"Inter",
         fontWeight: " 600",
        fontSize: "40px",
     
        lineHeight: "130%",
        "@media(max-width:600px)": {
            fontSize: "32px",
        },
        "@media(max-width:500px)": {
            fontSize: "27px",
        }
    },
    element: {
        display: "flex",
        flexDirection: "column",
        gap: "15px"
    },
    paragraph: {
        wordBreak: "keep-all",
        color: " #FFF",
      
        fontSize: "20px",
        fontFamily:"Inter",
        fontWeight: " 500",
        lineHeight: "150%",
        opacity: 0.8,
        "@media(max-width:500px)": {
            fontSize: "15px",
        }
    },
    gridItem: {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    },
    inputBox: {
        padding: "7px 20px",
        borderRadius: "50px",
        background: "rgba(255, 255, 255, 0.20)",
        maxWidth: "450px",
       

    },
    input: {
        color: " #FFF",
        fontFamily:"Inter",
        fontWeight: " 500",
        fontSize: "14px",
        fontStyle:"normal",
        lineHeight: "130%",
        background: "transparent",
        opacity:"100",
        border: "none",
        width:"90%",
        '& input::placeholder': {
            color: '#c7c5c5 !important', // Change this to the desired placeholder color
          },
       
        "& .MuiInputBase-root": {
            color: "#fff",
        },
        "& .MuiInput-underline:after":{
            borderBottom:"none"
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottom:"none"
        },
        "& .MuiInput-underline:before":{
            borderBottom:"none"
        }
    },
    some: {
        display: "flex",
        gap: "20px",
        "@media(max-width:600px)": {
            flexDirection: "column",
            gap: "0px",
        }
    },
    btn: {
        padding: '11px',
        borderRadius: "50px",
        background: "var(--brand, linear-gradient(136deg, #0C44AC 0%, #8338EC 100%))",
        color: "#fff",
        maxWidth: "125px",
        width: "100%",
        border: "none",
        fontSize: "14px",
        height:"43px",
        display:'flex',
        justifyContent:"center",
        alignItems:'center',
        fontFamily:"Inter",
        fontWeight: " 500",
        lineHeight: "130%",
        letterSpacing:"0.5px"
    },

    img: {
        width: "100%",
        borderRadius: "50px" 

    },
    dialogContent: {
        wordBreak: "keep-all",
        textAlign: "left",
        color: "#FFF", 
        fontSize: "17px",
        fontFamily:"Inter",
        fontWeight: " 500",
        lineHeight: "22px", /* 129.412% */
        letterSpacing: "-0.408px",
    },
    dialogBox: {
        display: "flex",
        gap: "20px",
        padding: "20px",
        paddingTop:"0px"
    }
})) 
const Home = () => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading]= useState(false)

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleButtonClick = () => {
        setDialogOpen(true);
    };
    const defaultValues = {
        email: '',
    };     


    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .required("Please enter your email")
            .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please enter a valid email")
            .max(256, 'Email cannot more then 256 characters'),

    });

    const JoinWaitList = async(values) => {
        try{
        setLoading(true)
            const res = await Axios({
                method:"POST",
                url:"https://nodepune-webapp.mobiloitte.io/api/v1/user/joinWaitingList",
                params:{
                    email: values.email.toString()
                },
            })
            if(res.data.responseCode === 200){
                setLoading(false);
                handleButtonClick();
        
              
            }
        } catch (error) {
              setLoading(false);
              if(error.response.data.responseMessage == "Join Waiting User already exists."){
                toast.error("Already subscribed")
              }else{
              toast.error(error.response.data.responseMessage)}
           
        }
    
    } 
    return (
        <div className={classes.main}>
            <Box className={classes.box}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <Box className={classes.mainContainer}>
                            <Box className={classes.logo}>
                                <img src="./images/logo.png" alt="" />
                                <Typography className={classes.text}>Vadait</Typography>
                            </Box>
                            <Box className={classes.gridItem}>
                                <Box className={classes.element}>
                                    <Typography className={classes.text2}> Embrace your wanderlust <br /> with vadait's AI Travel <br /> magic unleashed.</Typography>
                                    <Typography className={classes.paragraph}>Be the first to experience the future of travel: join our exclusive waitlist!</Typography>
                                </Box>
                                <Formik initialValues={defaultValues} validationSchema={validationSchema} onSubmit={(values,{resetForm})=>{JoinWaitList(values);resetForm();}} >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        touched,
                                        values,
                                    }) => (
                                        <Form action="" style={{ width: "100%" }} onSubmit={handleSubmit}>
                                            <Box className={classes.some}>
                                                <Box style={{ display: "flex", flexDirection: "column",maxWidth:"450px", width:"100%"}}>
                                                    <Box className={classes.inputBox}>
                                                        <TextField placeholder="Enter your mail here.." className={classes.input}
                                                            type="email"
                                                            name="email"
                                                            value={values.email}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={Boolean(touched.email && errors.email)}
                                                        />
                                                    </Box>
                                                    <p style={{ color: 'red',padding:"0px 20px" }}>{touched.email && errors.email}</p>
                                                </Box>
                                                <button className={classes.btn} type="submit">{
                                                loading ? <ButtonCircularProgress /> : "Join Waitlist"}</button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                        <Box className={classes.image}>
                            <img src="./images/phone.png" alt="" className={classes.img} />
                        </Box>
                    </Grid>
                </Grid>
                <Dialog
                    open={dialogOpen}

                    PaperProps={{
                        style: {
                            maxWidth: "484px",
                            width: "100%",
                            maxHeight: "100%",
                            borderRadius: "12px",
                            textAlign: "center",
                             background: "#242C32",
                             backgroundImage:"url('./images/Ellipse 48.png')",
                             backgroundRepeat: "no-repeat",
                             backgroundPositionY: "-50px",
                             backgroundPositionX: "-50px",
                            padding: " 12px 16px",
                        },
                    }}>
                    <Box style={{ display: 'flex', justifyContent: "end" }}>
                        <img src="./images/cross.svg" alt="" style={{cursor:"pointer"}} onClick={handleDialogClose} />
                    </Box>
                    <Box className={classes.dialogBox}>
                        <Box style={{ display: 'flex', alignItems: "center" }}>
                            <img src="./images/check.svg" alt=""  style={{maxWidth:"20px"}} />
                        </Box>
                        <Typography className={classes.dialogContent}> We’ve added your email address to  our waitlist. We’ll let you know when vadait is ready.{" "}</Typography>
                    </Box>
                </Dialog>
            </Box>
        </div>
    )
}

export default Home
