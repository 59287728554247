import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Footer from "./Footer"
const useStyles = makeStyles((theme) => ({
  main: {
    background:"url('./images/Gradient.png')",
    backgroundColor:"#141414",
    backgroundRepeat:"no-repeat",
    padding: "60px 20px 60px 20px"
  },
  mainContainer: {
    padding: "20px",
    borderRadius: "50px",
    background: "url('./images/About.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#FFF"
  },
  mainBox: {
    padding: "40px",
    paddingBottom: "0px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "@media(max-width:959px)": {
      padding: "10px",
    },
    "@media(max-width:500px)": {
      gap: "20px",
    }
  },
  heading: {
    color: "#FFF",
    fontFamily:"Inter",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: '600',
    lineHeight: "130%",
    "@media(max-width:400px)": {
      fontSize: "33px"
    }
  },
  paragraph: {
    wordBreak: "keep-all",
    color: "#fff",
    fontFamily:"Inter",
    fontWeight: " 500",
    fontSize: " 20px",
    fontStyle: " normal",
    lineHeight: "150%",
    opacity: "0.8",
    "@media(max-width:550px)": {
      fontSize: "15px"
    }
  },
  btn: {
    padding: '11px',
    borderRadius: "50px",
    background: "var(--brand, linear-gradient(136deg, #0C44AC 0%, #8338EC 100%))",
    color: "#fff",
    maxWidth: "125px",
    width: "100%",
    border: "none",
    fontSize: "14px",
    height: "50px",
    fontFamily: 'Sora',
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.5px"
  },
  img: {
    width: "100%",
    "@media(max-width:1400px)": {
      paddingTop: "100px"
    },
    "@media(max-width:959px)": {
      display: "none"
    }

  },
  img1: {
    display: "none",

    "@media(max-width:959px)": {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    }
  },
  mainBox1: {
    padding: "0px 40px 40px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "@media(max-width:959px)": {
      padding: "10px",
    },
    "@media(max-width:500px)": {
      gap: "20px",
    }
  },
  secend:{
    display:"flex",
    flexDirection:"column",
    gap:"15px"
  },
  mainHeading: {
    display: "flex",
    gap: "20px",

  }
}));

const LearnMore = () => {
  const classes = useStyles();
  const history = useHistory();
  const render = () => {
    history.push("./")
  };
  return (
    <>
      <div className={classes.main}>
        <Box className={classes.mainContainer}>

          <Box >
            <img src="./images/Bot with BG.png" alt="" className={classes.img1} onClick={render} />
          </Box>

          <Grid container>
            <Grid item lg={8} md={8} sm={12} xs={12} container direction="row-reverse" alignItems="center">
              <Box className={classes.mainBox}>
              <Box className={classes.secend}>
                <Box className={classes.mainHeading}>
                  <Box onClick={render}><img src="./images/backToPage.png" alt="" /></Box>
                  <Typography variant="h4" className={classes.heading}>About Us</Typography>
                </Box>

                <Typography className={classes.paragraph}>
                  Welcome to vadait, your gateway to an unparalleled travel experience where you'll journey like a celebrity,
                  with every detail thoughtfully taken care of by your personal AI concierge. Our mission is to empower every traveler
                  with the power of AI, ensuring that your trips are seamlessly planned, personalized to perfection, and filled with
                  unforgettable moments.
                </Typography>
                </Box>
                <Typography className={classes.paragraph}>
                  Imagine a world where travel planning becomes effortless – no more juggling between countless websites or spending
                  hours comparing options. With vadait, we are introducing a revolutionary concept in the world of travel. Our advanced AI
                  technology harnesses the vastness of travel data and the intricacies of individual preferences to curate itineraries tailored
                  precisely to your desires.
                </Typography>

              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box style={{ maxWidth: "450px" }}>
                <img src="./images/Bot with BG.png" alt="" className={classes.img} />
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.mainBox1}>
            <Typography className={classes.paragraph}>
              From the moment you set foot on vadait, you'll experience a travel planning process like never before.
              Your AI concierge will learn about your preferences, understand your unique tastes, and cater to your
              travel needs. No two travelers are alike, and vadait embraces this diversity, ensuring that each journey
              is a true reflection of who you are.
            </Typography>

            <Typography className={classes.paragraph}>Say goodbye to the hassle of making reservations or worrying about
              last-minute changes. Vadait takes care of all the bookings for you, securing the best options for flights,
              accommodations, activities, and more. And should you wish to modify your plans, no worries – vadait offers
              unparalleled flexibility, allowing you to edit and change your itinerary at your convenience.
            </Typography>
            <Typography className={classes.paragraph}>Our vision for vadait is to democratize the power of celebrity-like
              travel planning, making it accessible to every adventurer, dreamer, and explorer. We believe that
              memorable journeys should be within reach for all, and with the fusion of AI technology and human curation,
              we're making it happen.
            </Typography>
            <Typography className={classes.paragraph}>Whether you're embarking on a solo escapade, a romantic getaway, or a thrilling
              group expedition, Vadait will be your trusted companion throughout the voyage. With a perfect blend of
              cutting-edge technology and genuine human touch, we aim to elevate your travel experiences, creating
              cherished memories that last a lifetime.
            </Typography>
            <Typography className={classes.paragraph}>
              Welcome to vadait, where your dreams of effortless,
              personalized, and extraordinary travel become a reality. Prepare to embark on a journey like no other –
              travel like a celebrity, and let our AI-powered concierge take you to new heights of adventure
              and wonder. Your unforgettable voyage awaits!
            </Typography>
          </Box>

        </Box>

      </div>
      <Box>
        <Footer />
      </Box>
    </>
  );
}

export default LearnMore;
