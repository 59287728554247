import React from 'react'
import Home from "./Home"
import {makeStyles} from "@material-ui/core"
import Discover from './Discover'
import Revolution from './Revolution'
import AboutUs from './AboutUs'
import Experience from './Experiance'
import Footer from './Footer'


const useStyles=makeStyles((theme)=>({
    main:{
        background:"url('./images/Gradient.png')",
        backgroundColor:"#141414",
        backgroundRepeat:"no-repeat",
        
    }
}))
const LandingPage = () => {
    const classes=useStyles();
  return (
    <div className={classes.main}>
      <Home/>
      <Discover/>
       <Revolution/>
       <AboutUs/>
       <Experience/>    
       <Footer/>
    </div>
  )
}
export default LandingPage
