import React from 'react'
import { makeStyles, Grid, Box, Typography } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
    main: {
        padding: "30px 20px",
        "@media(max-width:500px)":{
            padding: "10px 20px",
            }
    },
   
    img:{
        width: "100%",   
    },
    img1:{
        width: "100%",   
        borderRadius:"10px"
    },
    subcontainer:{
        padding:"50px 20px",
        display:"flex",
        flexDirection:"column",
        gap:"50px",
        "@media(max-width:500px)":{
            padding: "10px 20px",
            gap:"25px",
            }
    },
    element:{
        display:"flex",
        flexDirection:"column",
        gap:"20px"   
    },
   
    paragraph:{
        wordBreak: "keep-all",
        color: " #FFF",
        fontStyle: " normal",
        fontFamily:"Inter",
        fontWeight: " 500",
        fontSize: "20px",   
        lineHeight: "150%",
        opacity: 0.800000011920929,
        "@media(max-width:500px)":{
            fontSize: "15px",
            }
    },
    text2: {
        wordBreak: "keep-all",
        color: " #FFF",
        fontFamily:"Inter",
        fontWeight: " 600",
        fontSize: "40px",
        lineHeight: "130%",
        "@media(max-width:500px)":{
            fontSize: "30px",
            }
    },
}))
const Discover = () => {
    const classes=useStyles();
  return (
    <div className={classes.main}>
        <Box>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box style={{padding:"10px"}}>
            <img src="./images/city.png" alt="" className={classes.img}/>
            </Box>
           
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.subcontainer}>
                <Box className={classes.element}>
                    <Typography className={classes.text2}>What is vadait?</Typography>
                    <Typography className={classes.paragraph}>With vadait, you no longer need to visit multiple websites for travel bookings. Our AI-powered 
                    concierge knows you, your needs, and takes care of all the bookings. Experience travel planning
                     like never before, with personalized itineraries, best recommendations, and the flexibility to 
                     edit and change whenever you want. The future of travel planning is here – vdait, your 
                     trusted companion for seamless and extraordinary journeys</Typography>
                </Box>
                <Box>
                <img src="./images/map.png" alt="" className={classes.img1}/>
                </Box>
            </Box>
        </Grid>
      </Grid>
      </Box>
    </div>
  )
}

export default Discover
