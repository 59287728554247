import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./views/pages/LandingPage/LandingPage";
import LearnMore from "./views/pages/LandingPage/LearnMore";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
              <ToastContainer />
      <BrowserRouter>
        <Switch> 
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/learnMore" component={LearnMore} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;