import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  main: {
    padding: "30px 20px"
  },
  mainContainer: {
    padding: "20px",
    borderRadius: "50px",
    background: "url('./images/Section 4.png')",
    backgroundRepeat:"no-repeat",
    backgroundSize:"cover",
    color: "#FFF",
    "@media(max-width:959px)":{
      borderRadius: "80px",
    },
    "@media(max-width:550px)":{
      borderRadius: "50px",
    }
  },
  mainBox: {
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "@media(max-width:959px)": {
      padding: "10px",
    },
    "@media(max-width:500px)": {
      gap: "20px",
    }
  },
  heading: {
    wordBreak: "keep-all",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: '600',
    lineHeight: "130%", /* 52px */
  },
  paragraph: {
    color: "#fff",

 
    wordBreak: "keep-all",
    fontFamily: "Inter",
    fontSize: " 20px",
    fontStyle: " normal",
    fontWeight: " 500",
    lineHeight: "150%",
    opacity: "0.8",
    "@media(max-width:550px)": {
      fontSize: "15px"
    }
  },
  btn: {
    padding: '11px',
    borderRadius: "50px",
    background: "var(--brand, linear-gradient(136deg, #0C44AC 0%, #8338EC 100%))",
    color: "#fff",
    maxWidth: "125px",
    width: "100%",
    border: "none",
    fontSize: "14px",
    height:"50px",
    fontStyle: " normal",
    fontFamily:"Inter",
    fontWeight: " 500",
    lineHeight: "130%",
    letterSpacing:"0.5px"
  },
  img: {
    width: "100%"
  },
  secend:{
    display:"flex",
    flexDirection:"column",
    gap:"15px"
  },
}));
const AboutUs = () => {
  const classes = useStyles();
  const history = useHistory();
  const render = () => {
  history.push("./learnMore")
};
  return (
    <div className={classes.main}>
      <Box className={classes.mainContainer}>
        <Grid container>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Box className={classes.mainBox}>
              <Box className={classes.secend}>
              <Typography variant="h4" className={classes.heading}>About Us</Typography>
              <Typography className={classes.paragraph}>
                Welcome to Vadait, your gateway to an unparalleled travel experience where you'll journey like a celebrity,
                with every detail thoughtfully taken care of by your personal AI concierge. Our mission is to empower every traveler
                with the power of AI, ensuring that your trips are seamlessly planned, personalized to perfection, and filled with
                unforgettable moments.
              </Typography>
              </Box>
              <Typography className={classes.paragraph}>
                Imagine a world where travel planning becomes effortless – no more juggling between countless websites or spending
                hours comparing options. With Vadait, we are introducing a revolutionary concept in the world of travel. Our advanced AI
                technology harnesses the vastness of travel data and the intricacies of individual preferences to curate itineraries tailored
                precisely to your desires.
              </Typography>
              <button className={classes.btn} onClick={render}>Learn More</button>
            </Box>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box>
              <img src="./images/Bot with BG.png" alt="" className={classes.img} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default AboutUs;
