import React from 'react'
import { makeStyles, Box, Typography } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
    main: { 
        
        padding: "33px",
        background: "#141414"
    },
    text: {
        color: "#FFF",
        fontFamily: "Playfair Display",
        fontSize: "29.948px",
        fontStyle: "normal",
        fontWeight: " 400",
        lineHeight: " 11.773px", /* 39.311% */
    },
    logo: {
        display: "flex",
        alignItems: "center",
        gap: "3px"
    },
    text1: {
        color: "#FFF",
        fontSize: "12px",
        fontStyle: "normal",
        fontFamily:"Inter",
        fontWeight: " 400",
        lineHeight: "normal",
        display: "flex",
        alignItems: "center"
    },
    some: {
        display: 'flex',
        justifyContent: "space-between",
        "@media(max-width:600px)": {
            flexDirection: "column",
            gap: "20px",
            alignItems: "center"
        }
    },
    icon: {
        display: "flex",
        gap: "30px",
        alignItems: "center"
    }
}))
const Footer = () => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Box className={classes.some}>
                <Box className={classes.logo}>
                    <img src="./images/logo.png" alt="" />
                    <Typography className={classes.text}>Vadait</Typography>
                </Box>
                <Box className={classes.icon}>
                    <img src="./images/facebook.png" alt="" />
                    <img src="./images/twitter.png" alt="" />
                    <img src="./images/telegram.png" alt="" />
                    <img src="./images/instagram.png" alt="" />
                </Box>
                <Typography className={classes.text1}>© 2023 All Rights Reserved</Typography>
            </Box>
        </div>
    )
}

export default Footer
