import React from 'react'
import { makeStyles, Grid, Box, Typography } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    main: {
        padding: "30px 20px"
    },
    mainContainer: {
        padding: "20px",
        borderRadius: "50px",
        background: "url('./images/Section 3- 1.png')",
        backgroundSize:"cover",
        "@media(max-width:599px)": {
            borderRadius: "90px",
        },
        "@media(max-width:400px)": {
            borderRadius: "70px",
        }
    },
    element: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
   
        borderRadius: "50px",
        paddingBottom: "30px",
        background:"url(./images/back.png)",
        backgroundSize:"cover"
    },
    image: {
        maxWidth: "500px",
        width: "100%",
        height: "auto",
    },
    img: {
        width: "100%",
        borderTopLeftRadius: "50px",
        borderTopRightRadius: "50px",
    },
    trext1: {
        color: "var(--dark-text, rgba(255, 255, 255, 0.90))",
        fontFamily:"Inter",
        fontSize: "18.101px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "23.273px", /* 128.571% */
    },
    trext2: {
        color: "rgba(255, 255, 255, 0.60)",
        fontFamily:"Inter",
        fontSize: " 12.93px",
        fontStyle: " normal",
        fontWeight: " 400",
        lineHeight: "23.273px", /* 180% */
    },
    rating: {
        display: "flex",
        paddingLeft: "12px",
        gap: "5px"
    },
    trext3: {
        color: "var(--dark-text, rgba(255, 255, 255, 0.90))",
        fontFamily: "Inter",
        fontSize: "18.101px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "18.273px", /* 128.571% */
    },
    textBox: {
        display: "flex",
        flexDirection: 'column',
        gap: "15px",
        padding: "23px",
        paddingLeft:"0",
        paddingRight: "50px",
        "@media(max-width:768px)": {
            padding:"20px"
        },
        "@media(max-width:350px)": {
            padding:"10px"
        }
    },
    textBox2: {
        display: "flex",
        flexDirection: 'column',
        gap: "15px",
        paddingBottom: "23px",
        paddingLeft:"0",
       
        "@media(max-width:768px)": {
            padding:"20px"
        },
        "@media(max-width:350px)": {
            padding:"10px"
        }
    },
    paragraph: {
        wordBreak: "keep-all",
        color: "#fff",
        fontFamily:"Inter",
        fontWeight: " 500",
        fontSize: " 20px",
        fontStyle: " normal",
        lineHeight: "150%",
        opacity: "0.8",
        "@media(max-width:550px)": {
            fontSize: "15px"
        }
    },
    heading: {
        wordBreak: "keep-all",
        color: "#fff",
        fontFamily:"Inter",
        fontWeight: " 500",
        fontSize: " 36px",
        fontStyle: " normal",
        fontWeight: " 600",
        lineHeight: "130%",
        "@media(max-width:550px)": {
            fontSize: "20px"
        }
    },
    mainContainer1: {
        padding: "50px 50px 0px 50px",
        borderRadius: "50px",
       
        background: "url('./images/Section 3- 1.png')",
        backgroundRepeat:"no-repeat",
        backgroundSize:"cover",
        "@media(max-width:600px)": {
            padding: "30px 30px 0px 30px",
        }
    },
    mainContainer4: {
        padding: "50px 50px 0px 50px",
        borderRadius: "50px",
       
        background: "url('./images/Section 3- 2.png')",
        backgroundRepeat:"no-repeat",
        backgroundSize:"cover",
        "@media(max-width:600px)": {
            padding: "30px 30px 0px 30px",
        }
    },
    mainContainer2: {
        padding: "0px 50px 0px 50px",
        borderRadius: "50px",
        background: "url('./images/Section 3- 3.png')",
        backgroundRepeat:"no-repeat",
        backgroundSize:"cover",
        "@media(max-width:959px)": {
            borderRadius: "90px",
        },
        "@media(max-width:600px)": {
            padding: "30px 30px 0px 30px",
        },
        "@media(max-width:550px)": {
            borderRadius: "70px",
        },
    },
    mobile: {
        maxWidth: "355px",
        width: "100%",
        "@media(max-width:1370px)":{
            height:"350px"
        },
        "@media(max-width:959px)":{
            height:"auto"
        }
    },
    mobile1: {
        maxWidth: "355px",
        width: "100%",
        paddingTop:"20px"
        
    },
    mobileBox: {
        display: "flex",
        justifyContent: "center",

    },
    textBox1: {
        display: "flex",
        flexDirection: 'column',
        gap: "15px",
        maxWidth:"605px",
        "@media(max-width:959px)": {
            padding: "50px 0px",
        }
    },
    mobileBox1: {
        display: "flex",
        justifyContent: "end",
        height: "100%",
        "@media(max-width:959px)": {
            justifyContent: "center",
        }
    },
    mainHeading: {
        wordBreak: "keep-all",
        textAlign: "center",
        fontFamily:"Inter",
        fontSize: "40px",
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: "130%",
        marginBottom: "40px",
        "@media(max-width:550px)": {
            fontSize: "25px",
        },
        "@media(max-width:350px)": {
            fontSize: "23px",
        }
    }
}))
const Revolution = () => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Typography className={classes.mainHeading}>Revolutionize your travels <br /> with our all-in-one app</Typography>
            <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Box className={classes.mainContainer}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={6} xs={12} >
                                <Box className={classes.element}>
                                    <Box className={classes.image}>
                                        <img src="./images/img1.png" alt="" className={classes.img} />
                                    </Box>
                                    <Box style={{ paddingLeft: "10px" }}>
                                        <Typography className={classes.trext1}>London</Typography>
                                        <Typography className={classes.trext2}>By Allen</Typography>
                                    </Box>
                                    <Box className={classes.rating}>
                                        <img src="./images/star.png" alt="" />
                                        <img src="./images/star.png" alt="" />
                                        <img src="./images/star.png" alt="" />
                                        <img src="./images/star.png" alt="" />
                                        <img src="./images/star 5.png" alt="" />
                                        <Typography className={classes.trext3}>5.0</Typography>
                                    </Box>

                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className={classes.element}>
                                    <Box className={classes.image}>
                                        <img src="./images/img2.png" alt="" className={classes.img} />
                                    </Box>
                                    <Box style={{ paddingLeft: "10px" }}>
                                        <Typography className={classes.trext1}>Washington - 15 days</Typography>
                                        <Typography className={classes.trext2}>by Allen</Typography>
                                    </Box>
                                    <Box className={classes.rating}>
                                        <img src="./images/star.png" alt="" />
                                        <img src="./images/star.png" alt="" />
                                        <img src="./images/star.png" alt="" />
                                        <img src="./images/star.png" alt="" />
                                        <img src="./images/star 5.png" alt="" />
                                        <Typography className={classes.trext3}>5.0</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box className={classes.textBox}>
                            <Typography className={classes.heading}>Share your travel itinerary with the community</Typography>
                            <Typography className={classes.paragraph}>Join our vibrant travel community to share your unique itineraries with like-minded adventurers.
                                Inspire, connect, and make every trip unforgettable. Embrace exploration and let your wanderlust thrive.
                                Start sharing today</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Box className={classes.mainContainer4}>
                        <Box className={classes.textBox2}>
                            <Typography className={classes.heading}>Plan trips with just a few taps</Typography>
                            <Typography className={classes.paragraph}>Whether you’re seasoned globetrotter or a first-time adventurer, our
                                travel companion is designed to enhance evert aspect of your travel experience.</Typography>
                        </Box>
                        <Box className={classes.mobileBox}>
                            <img src="./images/mobile.png" alt="" className={classes.mobile} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box style={{ paddingTop: "30px" }} >
                <Grid container className={classes.mainContainer2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box className={classes.mobileBox1}>
                            <img src="./images/SDS 2.png" alt="" className={classes.mobile1} />
                        </Box>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Box style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
                        <Box className={classes.textBox1}>
                            <Typography className={classes.heading}>AI-Powered itinerary creation: your ultimate travel companion</Typography>
                            <Typography className={classes.paragraph}>Experience the future of travel planning with AI itinerary creation.
                                Our advanced AI analyzes your preferences to curate personalized itineraries, blending popular landmarks with
                                hidden gems. Enjoy a seamless journey and savor every moment with our ultimate travel companion!</Typography>
                        </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Revolution
